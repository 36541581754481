import { create } from "zustand";
import { persist } from 'zustand/middleware';

//Points of header
export const useHeaderPoints=create(()=>({
    points: ['Домой','О проекте','Форум','Новости','Анонсы','Обменник','Обратная связь'],
    links: ['/','/other_pages/DescribingPage','/other_pages/ForumPage','https://t.me/homeless_man_news',
    'https://t.me/+-QoxYLpmpNMwOGM0','/other_pages/NotificationExchanger','/other_pages/FeedbackPage']
}));

//English version
export const useHeaderPointsTranslate=create(()=>({
    points: ['Home page','About','Forum','News','Announcements','Exchanger','Feedback'],
    links: ['/','/other_pages/DescribingPage','/other_pages/ForumPage','https://t.me/homeless_man_news',
    'https://t.me/+-QoxYLpmpNMwOGM0','/other_pages/NotificationExchanger','/other_pages/FeedbackPage']
}));

//Theme toggle
const useStore=create(persist(
    (set, get)=>({
        theme: "dark",
        setTheme: ()=>set((state)=>({
            ...state,
            theme: get().theme==="dark" ? "light" : "dark"
        })),
    }),
    {
        name: 'theme'
    }
));

export const useTheme=()=>useStore((state)=>state.theme);
export const useSetTheme=()=>useStore((state)=>state.setTheme);

//Wether discussions are opened
export const useOpen=create((set, get)=>({
  isOpened: false,
  setOpened: (unqId, messageId)=>{
    if (unqId===messageId){
        return get().isOpened=true;
    }
    else{
        return get().isOpened=false;
    }
  }
}));

//Translation
export const useTranslation=create((set, get)=>({
    isTranslated: false,
    setTranslation: ()=>set((state)=>({
        isTranslated: !state.isTranslated
    }))
}));