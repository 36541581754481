import Menu from './Menu'
import Main from './MainContent';
import Bottom from './Bottom';
import './styles/app.css';

function App() {
  return (
    <div className="App dark:bg-gray-700">
      <Menu />
      <Main />
      <Bottom />
    </div>
  );
}

export default App;
