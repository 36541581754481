import Homeless from './images/homeless.jpg';
import NightHomeless from './images/darkHomeless.jpg';
import { useTheme, useTranslation } from './store/store';
import './styles/main.css';

const Main=()=>{
    const isTranslated=useTranslation(state=>state.isTranslated);

    return(
        <main>
            <div>
                {   
                    isTranslated ?
                    <span className="dark:text-white">Trading by Homeless: <br></br> voices in the head</span> :
                    <span className="dark:text-white">Торговля по Бомжику: <br></br> голоса в голове</span>
                }
                <img src={useTheme()==='dark' ? NightHomeless : Homeless} draggable='false' alt='Homeless'></img>
            </div>
            {
                isTranslated ?
                <div className="dark:bg-gray-500 dark:text-gray-300">
                    Our project contains simple things about the main... There are no megatons of unnecessary information... <br></br>
                    You will get learn what markets really are... What is the role of news in the market,<br></br>
                    and what is the role of charts... And most importantly: <br></br> 
                    how to use only the necessary resources as efficiently as possible...
                </div> : 
                <div className="dark:bg-gray-500 dark:text-gray-300">
                    В нашем проекте собраны простые вещи о главном... Здесь нет мегатонн ненужной воды... <br></br>
                    И вы не заблудитесь в мегабайтах ненужной информации... Вы узнаете, что такое рынки на самом деле... <br></br>
                    Какая роль отведена на рынке новостям, а какая графикам... И главное: как максимально эффективно <br></br>
                    использовать только необходимые ресурсы...
                </div>
            }
        </main>
    );
}

export default Main;