import Menu from "../Menu";
import { Main } from "./describing_components/Main";
import { Footer } from "./describing_components/Footer";

export const DescribingPage=()=>{
    return(
        <div className="App dark:bg-gray-700"> 
            <Menu />
            <Main />
            <Footer />
        </div>
    );
}