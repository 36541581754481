import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from 'firebase/auth';
import { getFirestore, onSnapshot, collection, addDoc, orderBy, query, serverTimestamp } from 'firebase/firestore';
import { auth, app } from '../../firebase';
import { useState, useRef } from 'react';
import { useEffect } from 'react';
import { useTranslation } from '../../store/store';

const db=getFirestore(app);
 
export const Authorization=()=>{
    const isTranslated=useTranslation(state=>state.isTranslated);

    const [user, setUser]=useState(null);

    useEffect(()=>{
        onAuthStateChanged(auth, user=>{
            if (user){
                setUser(user);
            }
            else{
                setUser(null);
            }
        });
    }, []);

    const handleLoginWithGoogle=async ()=>{
        const provider=new GoogleAuthProvider();
        
        try{
            const result=await signInWithPopup(auth,provider)
        }
        catch(err){
            console.log(err);
        }
    }

    return (
        <>
            {
                user ?
                <Chat user={user} isTranslated={isTranslated} /> :
                <SignUp login={handleLoginWithGoogle} isTranslated={isTranslated} />
            }
        </>
    );
}

const SignUp=(props)=>{
    return(
        <section className='enter'>
            {
                props.isTranslated ? 
                <>
                    <h1 className='to-get-in App dark:text-white'>Sign in to enter the chat</h1>
                    <button className='log-in' onClick={props.login}>Sign in</button>
                </> : 
                <>
                    <h1 className='to-get-in App dark:text-white'>Авторизуйтесь для того, чтобы войти в наш чат</h1>
                    <button className='log-in' onClick={props.login}>Авторизоваться</button>
                </>
            }
        </section>
    );
}

const Chat = (props) => {
    const [newMessage, setNewMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const containerRef = useRef(null);

    useEffect(() => {
        const q = query(collection(db, 'messages'), orderBy('timestamp'));
        const unsubscribe = onSnapshot(q, snapshot => {
            setMessages(snapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })));
            if (containerRef.current) {
                containerRef.current.scrollTop = containerRef.current.scrollHeight;
            }
        });
        return unsubscribe;
    }, []);

    const sendMessage=async ()=>{
        await addDoc(collection(db, "messages"), {
            uid: props.user.uid,
            photoURL: props.user.photoURL,
            displayName: props.user.displayName,
            email: props.user.email,
            text: newMessage,
            timestamp: serverTimestamp(),
        });
        setNewMessage("");
    };

    return(
        <main className='chat'>
            <section className='message-container' ref={containerRef}>
                <div>
                    {messages.map((msg, index) => (
                        <div key={msg.id} className={`message App dark:text-white ${msg.data.uid === props.user.uid ? 'own-message' : 'other-message'}`}>
                            {
                                msg.data.email==='ogonannikita52@gmail.com' ||
                                msg.data.email==='ivan.arvg@gmail.com' || 
                                msg.data.email==='r.ogonian@yandex.ru'     
                                ? <span className='admin'>Admin</span> :
                                <span className='nick-usual dark:text-white'>{msg.data.displayName}</span>
                            }
                            <img src={msg.data.photoURL} alt="User Avatar" draggable='false' />
                            <span>{msg.data.text}</span>
                        </div>
                    ))}
                </div>
            </section>
            <section>
                <input value={newMessage} onChange={e => setNewMessage(e.target.value)} className='App dark:text-white' />
                {
                    props.isTranslated ? <button onClick={sendMessage} className='App dark:text-white'>Send</button> : 
                    <button onClick={sendMessage} className='App dark:text-white'>Отправить</button>
                }
            </section>
        </main>
    );
};
