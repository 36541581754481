import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useRef } from "react";
import { getFirestore } from "firebase/firestore";
import { app } from "../../firebase";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "../../store/store";
const db=getFirestore(app);

export const AddComment=(props)=>{
    const isTranslated=useTranslation(state=>state.isTranslated);

    const name=useRef();

    const generateUniqueId = () => {
        return uuidv4();
    };
    const generateId = generateUniqueId()

    const sendMessage=async (e)=>{
        e.preventDefault();

        const currentText = name.current.value;

        if (document.querySelector('.comment-area').value===''){
            console.log('пустое значение')
        }
        else{
            await addDoc(collection(db, "comments"), {
                unqId: generateId,
                uid: props.user.uid,
                photoURL: props.user.photoURL, 
                displayName: props.user.displayName,
                text: currentText,
                timestamp: serverTimestamp()
            });
    
            e.target.reset();
        }
    }
    
    return(
        <form className='add-comment' onSubmit={sendMessage}>
            <input type='text' ref={name} className='comment-area text-black' />
            {
                isTranslated ? 
                <button className='App dark:text-white leave-comment'>Send</button> :
                <button className='App dark:text-white leave-comment'>Отправить</button>
            }
        </form>
    );
}