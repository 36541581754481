import './styles/header.css';
import { useHeaderPoints, useHeaderPointsTranslate, useTranslation } from './store/store';
import { useTheme, useSetTheme } from './store/store';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdNightlight } from "react-icons/md";
import { MdOutlineTranslate } from "react-icons/md";
import { FiSun } from "react-icons/fi";

const Menu=()=>{
    const points=useHeaderPoints(state=>state.points);
    const links=useHeaderPoints(state=>state.links);

    const pointsTranslated=useHeaderPointsTranslate(state=>state.points);
    const links1=useHeaderPointsTranslate(state=>state.links);

    const theme=useTheme();
    const setTheme=useSetTheme();
    useEffect(()=>{
        try{
            const localTheme=JSON.parse(localStorage.getItem('theme'));
            if (localTheme){
                document.documentElement.setAttribute('data-mode', localTheme.state.theme);
                document.documentElement.className=localTheme.state.theme;
            }
        }
        catch (err){
            console.log('error loading the colour theme');
        }
    }, [theme]);

    const isTranslated=useTranslation(state=>state.isTranslated);
    const setTranslate=useTranslation(state=>state.setTranslation);
    
    return(
        <header>
            <nav>
                <div className={isTranslated ? 'closed' : 'untranslated-points'}>
                    {points.map((el,i)=>{
                        return <Link to={links[i]} key={i} className='dark:text-white link'>{el}</Link>
                    })}
                </div>
                <div className={isTranslated ? 'translated-points' : 'closed'}>
                    {pointsTranslated.map((el,i)=>{
                        return <Link to={links1[i]} key={i} className='dark:text-white link'>{el}</Link>
                    })}
                </div>
                <div className='toggles'>
                    {theme==='light' ? <MdNightlight onClick={setTheme} className='theme' /> :
                    <FiSun onClick={setTheme} color='#FFF' className='theme' />}
                    {<MdOutlineTranslate onClick={setTranslate} className='translate dark:text-white' />}
                </div>
            </nav>
        </header>
    );
}

export default Menu;