import './styles/main.css';
import Homeless from '../../images/homeless.jpg';
import NightHomeless from '../../images/darkHomeless.jpg';
import { useTheme, useTranslation } from '../../store/store';

export const Main=()=>{
    const isTranslated=useTranslation(state=>state.isTranslated);
    return(
        <main className='main-describing'>
            <section className="dark:text-white">
                {
                    isTranslated ? 
                    <div>
                        Our project contains simple things about the main... <br></br>
                        There are no megatons of unnecessary information here... <br></br>
                        And you will not get lost in megabytes of unnecessary information... <br></br>
                        Everything will be simple and clear... <br></br>
                        So, you will find out what markets really are... <br></br>
                        How it works and why exactly this way, and not otherwise... <br></br>
                        What role is assigned to the market news and what's to the charts... <br></br>
                        And most importantly: how to make the most effective <br></br>
                        Use of only the necessary resources <br></br>
                        And come to a result...
                    </div> : 
                    <div>
                        В нашем проекте собраны простые вещи о главном... <br></br> Здесь нет мегатонн ненужной воды... <br></br>
                        И вы не заблудитесь в мегабайтах ненужной информации... <br></br> Всё будет просто и понятно... <br></br>
                        Итак, вы узнаете, что такое рынки на самом деле... <br></br> Как это устроено и почему именно так, а не иначе... <br></br>
                        Какая роль отведена на рынке новостям, а какая графикам... <br></br> И главное: как максимально эффективно <br></br>
                        использовать только необходимые ресурсы <br></br> и приходить к результату...
                    </div>
                }
                <img src={useTheme()==='dark' ? NightHomeless : Homeless} alt='homeless' draggable='false'></img>
            </section>
        </main>
    );
}