import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDYGHj5KwGkpq-jwY8AHNl7M3BW2w_m7mM",
  authDomain: "chat-783b8.firebaseapp.com",
  projectId: "chat-783b8",
  storageBucket: "chat-783b8.appspot.com",
  messagingSenderId: "734947900115",
  appId: "1:734947900115:web:c7f7a2b0e20cdf026b7226",
  measurementId: "G-P2LMP9XG1J"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {auth,app}