import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from 'firebase/auth';
import { getFirestore, collection, orderBy, query, onSnapshot } from 'firebase/firestore';
import { auth } from '../../firebase';
import { useState } from 'react';
import { useEffect } from 'react';
import { app } from '../../firebase';
import './styles/main.css';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { AnswerList } from './AnswerList';
import { AddComment } from './AddComment';
import { LiaCommentsSolid } from "react-icons/lia";
import { useOpen, useTranslation } from '../../store/store';

const db=getFirestore(app);

export const Authorization=()=>{
    const [user, setUser]=useState(null);

    useEffect(()=>{
        onAuthStateChanged(auth, user=>{
            if (user){
                setUser(user);
            }
            else{
                setUser(null);
            }
        });
    }, []);

    const handleLoginWithGoogle=async ()=>{
        const provider=new GoogleAuthProvider();
        
        try{
            const result=await signInWithPopup(auth,provider)
        }
        catch(err){
            console.log(err);
        }
    }

    return (
        <>
            {
                user ?
                <Forum user={user} /> :
                <SignUp login={handleLoginWithGoogle} />
            }
        </>
    );
}

const SignUp=(props)=>{
    const isTranslated=useTranslation(state=>state.isTranslated);
    return(
        <section className='enter enter-forum'>
            {
                isTranslated ?
                <>
                    <h1 className='to-get-in App dark:text-white'>Sign in to enter the forum</h1>
                    <button className='log-in' onClick={props.login}>Sign in</button> 
                </> :
                <>
                    <h1 className='to-get-in App dark:text-white'>Авторизуйтесь для того, чтобы войти в наш форум</h1>
                    <button className='log-in' onClick={props.login}>Авторизоваться</button>
                </>
            }
        </section>
    );
}

export const Forum=(props)=>{
    const queryComs = query(collection(db, "comments"), orderBy("timestamp", "desc"));
    const [docs, loading, error] = useCollectionData(queryComs);
    const setIsOpen=useOpen(state=>state.setOpened);
    const isOpened=useOpen(state=>state.isOpened);

    const openComments = (unqId, messageId) => {
        setIsOpen(unqId, messageId);
        console.log(isOpened);
    }

    return (
        <main className='forum dark:text-white'>
            <AddComment user={props.user} />
            <section className='comments-container'>
                {loading && <span className='load-alert'>loading...</span>}
                {docs && docs.map((doc) => (
                    <div>
                        <div className='comments-section comment-${doc.id}' key={doc.id}>
                            <span className='nickname dark:text-white'>{doc.displayName}</span>
                            <img src={doc.photoURL} alt='ava' className='ava-comment' />
                            <div>{doc.text}</div>
                        </div>
                        <AnswerList messageId={doc.unqId} user={props.user} />
                    </div>
                ))}
            </section>
        </main>
    );
};