import Menu from "../Menu";
import { Authorization } from "./forum_components/MainContent";
import { Footer } from "./forum_components/Footer";

export const ForumPage=()=>{
    return(
        <div className="App dark:bg-gray-700 dark:text-white">
            <Menu />
            <Authorization />
            <Footer />
        </div>
    );
}