import Bottom from "../Bottom";
import Menu from "../Menu";
import { useTranslation } from "../store/store";

export const ExchangerNotification=()=>{
    const isTranslated=useTranslation(state=>state.isTranslated);
    const mainStyle={
        'display' : 'flex',
        'flexGrow' : '1',
        'justifyContent' : 'center',
        'alignItems' : 'center',
        'fontSize' : '35px',
        'fontWeight' : 'bold'
    }
    const allStyle={
        'height' : '100vh'
    }
    if (isTranslated){
        return <div className="App dark:bg-gray-700" style={allStyle}>
            <Menu />
            <main style={mainStyle}>
                <marquee className="dark:text-white" behavior="scroll" direction="right" scrollamount="17">Yet is being developeded</marquee>
            </main>
            <Bottom />
        </div>
    }
    else{
        return <div className="App dark:bg-gray-700" style={allStyle}>
            <Menu />
            <main style={mainStyle}>
                <marquee className="dark:text-white" behavior="scroll" direction="right" scrollamount="17">В процессе разработки</marquee>
            </main>
            <Bottom />
        </div>
    }
}