import Telegram from '../../images/tg.png';
import { useTranslation } from '../../store/store';

export const Footer=()=>{
    const isTranslated=useTranslation(state=>state.isTranslated)
    return(
        <footer className='dark:bg-gray-500 forum-footer'>
            <div><span>2024</span> Homeless</div>
            <div>{isTranslated ? <span>Connect with me</span> : <span>Связаться со мной</span>}<img src={Telegram} alt='tg' onClick={toTg}></img></div>
        </footer>
    );
    function toTg(){
        window.location.href='https://t.me/homeless_man_from_heating_main';
    }
}