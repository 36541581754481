import { useEffect, useRef, useState } from 'react';
import { addDoc, collection, onSnapshot, orderBy, query, serverTimestamp, where } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { app } from '../../firebase';
import { useTranslation } from '../../store/store';

const db = getFirestore(app);

export const AnswerList = (props) => {
    const isTranslated=useTranslation(state=>state.isTranslated);

    const name = useRef();

    const sendMessage = async (e) => {
        e.preventDefault();

        const reply = name.current.value;

        await addDoc(collection(db, "replies"), {
            messageId: props.messageId,
            uid: props.user.uid,
            photoURL: props.user.photoURL,
            displayName: props.user.displayName,
            text: reply,
            timestamp: serverTimestamp()
        });

        e.target.reset();
    }

    const [replies, setReplies] = useState([]);

    useEffect(() => {
        const q = query(collection(db, "replies"), where("messageId", "==", props.messageId), orderBy("timestamp", "desc"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const repliesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setReplies(repliesData);
        });
        return unsubscribe;
    }, [props.messageId]);

    return (
        <form onSubmit={sendMessage} className='repl'>
            <input type='text' ref={name} className='reply-area dark:text-black' />
            {
                isTranslated ?
                <>
                    <button className='App dark:text-white leave-reply'>Answer</button>
                </> :
                <>
                    <button className='App dark:text-white leave-reply'>Ответить</button>
                </>
            }
            <div>
                {replies.map(reply => (
                    <div>
                        <span className='nickname dark:text-white'>{reply.displayName}</span>
                        <img src={reply.photoURL} alt='ava' className='reply-ava' />
                        <span>{reply.text}</span>
                    </div>
                ))}
            </div>
        </form>
    );
};