import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { DescribingPage } from './other_pages/DescribingPage';
import { ForumPage } from './other_pages/ForumPage';
import { ExchangerNotification } from './other_pages/NotificationExchanger';
import { Feedback } from './other_pages/FeedbackPage';
import { NotFound } from './NotFound';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import './styles/index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route path="/other_pages/DescribingPage" element={<DescribingPage />} />
        <Route path="/other_pages/ForumPage" element={<ForumPage />} />
        <Route path="/other_pages/NotificationExchanger" element={<ExchangerNotification />} />
        <Route path="/other_pages/FeedbackPage" element={<Feedback />} />
        
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
